.iti {
    @apply w-full;
}

.iti__country-name {
    @apply text-gray-800;
}

.iti__flag {background-image: url("../images/intl-tel-input/flags.png");}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .iti__flag {background-image: url("../images/intl-tel-input/flags@2x.png");}
}

