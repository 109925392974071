/* Google Autocomplete Dropdown Styling */
.pac-container {
    @apply border shadow-none;

    .pac-item {
        @apply font-sans;

        .pac-item-query {
            @apply text-gray-800;
        }

        &:hover, &:active {
            @apply text-purple-200;

            .pac-item-query {
                @apply text-purple-200;
            }
        }

        .pac-icon.pac-icon-marker {
            @apply bg-gray-500;

            -webkit-mask-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHRpdGxlPnBpbjwvdGl0bGU+PHBhdGggZD0iTTEyLDBBOC4wMDksOC4wMDksMCwwLDAsNCw4YzAsMy41MSw1LDEyLjAyNSw3LjE0OCwxNS41MjRBMSwxLDAsMCwwLDEyLDI0YS45OTEuOTkxLDAsMCwwLC44NTItLjQ3N0MxNSwyMC4wMjYsMjAsMTEuNTE0LDIwLDhBOC4wMDksOC4wMDksMCwwLDAsMTIsMFptMCwxMS41QTMuNSwzLjUsMCwxLDEsMTUuNSw4LDMuNSwzLjUsMCwwLDEsMTIsMTEuNVoiLz48L3N2Zz4=") !important;
            -webkit-mask-size: contain;
            -webkit-mask-repeat: no-repeat;
            -webkit-mask-position: center center;
        }
    }
}