.toasted {
    line-height: 1 !important;

    &.toasted-primary {
        @apply rounded-md font-normal p-4 justify-start !important;

        &.default,
        &.error {
            background-color: theme('colors.green.DEFAULT') !important;
            color: #fff !important;
            text-align: left;

            .action {
                font-size: theme('fontSize.sm') !important;
                letter-spacing: theme('letterSpacing.wide') !important;
                margin: 0 !important;
                margin-left: theme('margin.6') !important;
                padding: 0 !important;
            }
        }

        &.error {
             background-color: theme('colors.red.DEFAULT') !important;
             color: #fff !important;
        }

        &.error {
            .action {
                @apply grow text-right !important;
                color: theme('colors.red.light') !important;
            }
        }
    }

    &.featureNotification {
        @apply leading-normal rounded-md p-6 max-w-md !important;

        &.default {
             @apply bg-white shadow-lg text-base;

            .title {
                @apply flex items-center text-teal font-semibold pb-2;

                img {
                    @apply h-8;
                }

                span {
                    @apply ml-2;
                }
            }

            .message {
                @apply font-normal italic text-gray-400;
            }

            a.action.ripple::before {
                @apply block opacity-25;

                width: 24px;

                content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Ctitle%3Eclose-circle%3C/title%3E%3Cdesc%3Edelete remove%3C/desc%3E%3Cpath d='M17.666,6.333a1.249,1.249,0,0,0-1.768,0l-3.722,3.722a.25.25,0,0,1-.353,0L8.1,6.333A1.25,1.25,0,1,0,6.333,8.1l3.722,3.722a.25.25,0,0,1,0,.354L6.333,15.9a1.251,1.251,0,0,0,0,1.768,1.269,1.269,0,0,0,1.768,0l3.722-3.722a.249.249,0,0,1,.353,0L15.9,17.666a1.272,1.272,0,0,0,1.768,0,1.251,1.251,0,0,0,0-1.768l-3.722-3.722a.25.25,0,0,1,0-.354L17.666,8.1A1.249,1.249,0,0,0,17.666,6.333Z'/%3E%3Cpath d='M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0Zm0,22A10,10,0,1,1,22,12,10.011,10.011,0,0,1,12,22Z'/%3E%3C/svg%3E");
            }
        }
    }
}

.toasted-container {
    &.bottom-left {
        padding-right: theme('padding.2') !important;
        padding-left: theme('padding.2') !important;
        bottom: 1rem !important;

        @screen md {
            padding: 0 !important;
            left: 2rem !important;
            bottom: 2rem !important;
        }
    }

    &.top-right {
        padding-right: theme('padding.2') !important;
        padding-left: theme('padding.2') !important;

        @screen md {
            padding: 0 !important;
            right: 2rem !important;
        }
    }

    &.bottom-center {
        transform: none !important;
    }
}
