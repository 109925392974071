  .password-strength-meter-bars {
    background: transparent;
    height: inherit;
    position: absolute;
    width: 0;
    border-radius: inherit;
    transition: width 0.5s ease-in-out, background 0.25s;
  }

.password-strength-meter-bars[data-score='0'] {
    background: red;
    width: 20%;
}

.password-strength-meter-bars[data-score='1'] {
    background: orange;
    width: 40%;
}

.password-strength-meter-bars[data-score='2'] {
    background: yellowgreen;
    width: 60%;
}

.password-strength-meter-bars[data-score='3'] {
    background: green;
    width: 80%;
}

.password-strength-meter-bars[data-score='4'] {
    background: darkgreen;
    width: 100%;
}
