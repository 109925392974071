.autosuggest__input--open {
    @apply border-b-2 border-purple-light;
}

.autosuggest__results-container {
    @apply relative w-full;
}

.autosuggest__results {
    @apply absolute w-full p-2 bg-white border shadow z-10;
}

.autosuggest__results .autosuggest__results-item {
    @apply p-2 cursor-pointer rounded-md text-sm;
}

.autosuggest__results .autosuggest__results-item:active,
.autosuggest__results .autosuggest__results-item:hover,
.autosuggest__results .autosuggest__results-item:focus,
.autosuggest__results .autosuggest__results-item.autosuggest__results-item--highlighted {
    @apply text-purple bg-gray-100;
}
