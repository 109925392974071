@import 'intl-tel-input/build/css/intlTelInput.css';
@import 'slick-carousel/slick/slick.css';
@import 'tiny-slider/dist/tiny-slider';
@import 'vue-draggable-resizable/dist/VueDraggableResizable.css';
@import '@sum.cumo/vue-datepicker/dist/Datepicker.css';

@import 'plugins/animate';
@import 'plugins/autosuggest';
@import 'plugins/devices.min.css';
@import 'plugins/intercom';
@import 'plugins/location-picker';
@import 'plugins/mobile-window';
@import 'plugins/password-strength-meter';
@import 'plugins/scroller';
@import 'plugins/sweetalert';
@import 'plugins/toasts';

@import "vendor/intl-tel-input-overrides.css";
@import 'vendor/pixie-overrides.css';
@import 'vendor/quill-overrides.css';
@import 'vendor/resizer-overrides.css';
@import 'vendor/vue-datepicker-overrides.css';
@import 'vendor/tippy-themes.css';

.StripeElement--focus {
    @apply shadow;
}

.slick-slider, .slick-list, .slick-track, .slick-slide, .slick-slide > div {
    height: 100%;
}

.slick-slide img {
    /* Optimize the image placement by using this hack to the create a new stack context and utilize the GPU */
    -webkit-transform: rotateZ(360deg);
}
