.contain .ql-container p {
    @apply text-sm w-full float-none p-2;
}

.ql-container .tooltip {
    @apply min-w-0;
}

.ql-container .tooltip-inner {
    @apply leading-none rounded-sm py-2 px-1 text-xs;
}

.ql-container .tooltip .top .in {
    @apply text-xs;
}

.ql-container .modal-backdrop {
    @apply bg-transparent;
}

.ql-container .modal .modal-header {
    @apply p-6;
}

.ql-container .modal .modal-header .close {
    @apply cursor-pointer m-0 text-base;
}

.ql-container .modal .modal-header h4 {
    @apply text-base font-medium leading-loose m-auto;
}

.ql-container .modal .modal-body textarea {
    @apply bg-white;
}

.ql-bubble .ql-toolbar {
    @apply pl-0;
}

.ql-bubble .ql-toolbar .ql-formats {
    @apply text-gray-400;
}

.ql-bubble .ql-toolbar .vc-sketch {
    @apply top-0 mt-12 ml-2;
}

.ql-bubble .ql-toolbar .vc-sketch .vc-sketch-field .vc-input__input {
    @apply text-black;
}

#buttonEditor {
    @apply absolute mt-2 bg-gray-700 p-4 rounded-xl;

    left: -20px;
    right: -20px;
}

#buttonEditor p {
    @apply text-white py-1 font-bold;
}

#buttonEditor .color-bar {
    @apply h-2 rounded-md;
}

#buttonEditor .vc-sketch {
    margin-top: -150px;
    margin-left: 170px;
}

#buttonEditor .vc-sketch .vc-sketch-field .vc-editable-input input {
    @apply text-black;
}

#buttonEditor .button-editor-link {
    @apply cursor-pointer inline-block mt-2 px-4 font-bold;
}
