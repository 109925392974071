.pixie-editor {
    z-index: 99999;
}

.pixie-editor toolbar, .pixie-editor google-fonts-panel .header, .pixie-editor .apply-button {
    @apply bg-purple;
}

.pixie-editor .apply-button {
    @apply text-white;
}
