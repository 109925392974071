.vb > .vb-dragger {
    z-index: 5;
    width: 9px;
    right: 8px;
}

.vb > .vb-content {
    @apply overflow-y-scroll !important;
    -webkit-overflow-scrolling: touch; /* Smoother scrolling on iOS devices */
}

.vb > .vb-dragger > .vb-dragger-styler {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: rotate3d(0,0,0,0);
    transform: rotate3d(0,0,0,0);
    -webkit-transition: background-color 100ms ease-out, margin 100ms ease-out,
        height 100ms ease-out;
    transition: background-color 100ms ease-out, margin 100ms ease-out,
        height 100ms ease-out;
    background-color: rgba(255,255,255,0.3);
    margin: 5px 0px 5px 0;
    border-radius: 20px;
    height: calc(100% - 10px);
    display: block;
}

.vb.vb-scrolling-phantom > .vb-dragger > .vb-dragger-styler {
    background-color: rgba(255,255,255,0.3);
}

.vb > .vb-dragger:hover > .vb-dragger-styler {
    background-color: rgba(255,255,255,0.3);
    height: 100%;
}

.vb.vb-dragging > .vb-dragger > .vb-dragger-styler {
    background-color: rgba(255,255,255,0.3);
    margin: 0px;
    height: 100%;
}

.vb.vb-dragging-phantom > .vb-dragger > .vb-dragger-styler {
    background-color: rgba(255,255,255,0.3);
}
