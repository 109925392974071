.vdp-datepicker {
    > div {
        @apply flex flex-row items-center;
    }
}

.vdp-datepicker__calendar {
    @apply text-gray-800;

    .disabled {
        @apply text-gray-400 cursor-not-allowed;
    }

    .cell {
        &:not(.blank):not(.disabled).day,
        &:not(.blank):not(.disabled).month,
        &:not(.blank):not(.disabled).year {
          &:hover {
            @apply bg-purple-light rounded-lg text-white border-purple;
          }
        }
    
        &.selected {
            @apply bg-purple-600 text-white rounded-lg;
    
          &:hover {
            @apply bg-purple-lighter;
          }
        }
    
        &.muted {
            @apply text-gray-600;

            &.selected {
                @apply text-white;
            }
        
            &.disabled:not(.selected) {
                @apply text-gray-400 cursor-not-allowed;
            }
        }
      }
}
