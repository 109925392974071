.tippy-tooltip.white-theme {
    background-color: white;
    color: black;
    padding: 0;
    @apply shadow-xl;
}

.tippy-tooltip.white-theme[data-animatefill] {
    background-color: transparent;
}

.tippy-tooltip.white-theme .tippy-backdrop {
    background-color: transparent;
}

.tippy-tooltip.bold-theme {
    @apply font-semibold;
}
