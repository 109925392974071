/**
 * This overrides the Animate.css pulse keyframe to have a more exaggerated
 * scale and shift the x-axis away from the edge of the block.
 */

@keyframes pulse {
    from {
        transform: scale3d(1, 1, 1);
    }
    50% {
        transform: scale3d(1.15, 1.15, 1.15) translateX(45px);
    }
    to {
        transform: scale3d(1, 1, 1);
    }
}

/* A 'helper' class for making elements spin */
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.spinning {
    animation: spin 1s infinite linear;
}
