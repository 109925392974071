.swal2-popup .swal2-content {
    @apply leading-normal font-normal text-gray-700 !important;
}

.swal2-title {
    @apply leading-snug;
}

.swal2-input {
    @apply w-10/12 bg-white appearance-none py-3 px-4 border border-gray-200 rounded-md text-gray-800 leading-normal duration-200 ease-in-out transition-all shadow-none !important;

    transition-duration: .2s !important;
    transition-property: all !important;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
    border-bottom-color: theme('colors.gray.200') !important;
    border-bottom-width: theme('borderWidth.2') !important;

    &:hover {
         @apply border-purple-200 !important;
     }

    &::placeholder {
         @apply text-gray-400 !important;
     }

    &:focus {
         @apply border-purple-lighter outline-none !important;
     }
    }

.swal2-checkbox .swal2-label {
    @apply text-sm;
}

.swal2-validation-message {
    @apply bg-transparent font-bold !important;
}

.swal2-wide {
    width: 40em;
}
